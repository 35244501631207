<template>
  <section>
    <section class="navBack">
      <v-container class="container-custom">
        <v-row>
          <v-col md="12" class="topNav">
            <ul class="navList">
              <li>Home</li>
              <li><v-icon> mdi-chevron-right </v-icon>My Cancellation</li>
            </ul>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section class="my-5 mb-15">
      <v-container class="container-custom">
        <v-row>
          <v-col md="3">
            <Navigation />
          </v-col>
          <v-col md="9" cols="12">
            <h2 class="mb-5">My Cancellation</h2>
            <div class="filterationBox" v-if="!loading">
              <ul class="orders">
                <li v-if="orders.length == 0">No Order Found</li>
                <li v-for="(data, index) in orders" :key="(index + 'order')">
                  <div class="offerCard">
                  
                    <div class="offerDescription">
                      <router-link :to="`/order/${data.subOrderCode}`"
                        ><p class="offerTitle">
                          {{ data.subOrderCode }}
                        </p></router-link
                      >
                      <p><strong>Place on</strong> : {{ data.createdAt | moment("MMMM Do YYYY : hh :mm A") }}</p>
                      <p class="storename">
                        <strong>Items</strong> : {{ data.totalItem }}
                      </p>
                      <p class="storename">
                        <strong>Total Amount</strong> : Rs.{{ data.totalPrice }}
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="filterationBox" v-if="loading">
              <Loading />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </section>
</template>

<script>
import Navigation from "@/components/user/Navigation";
import Loading from "@/components/Loading";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "MyCancelledOrders",
  data: () => ({
    limit: 10,
    page: 1,
    typeoforder: "Cancelled",
    orders: [],
    loading: true,
  }),
  components: {
    Navigation,
    Loading,
  },
  methods: {
    ...mapActions(["getOrders"]),
  },
  computed: {
    ...mapGetters(["allOrders"]),
  },
  async created() {
    await this.getOrders({
      limit: this.limit,
      page: this.page,
      typeoforder: this.typeoforder,
    });
    this.orders = this.allOrders.results;
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.navBack {
  background: #f4f4f4;
  padding: 10px 0;
}
.navList {
  padding-left: 0;
  list-style: none;
  display: flex;
  font-size: 14px;
  li {
    .v-icon {
      font-size: 18px;
      margin-left: 5px;
    }
    // margin-right: 15px;
  }
}
.v-btn {
  min-height: 5 0px;
  width: 200px;
  color: #0878d4 !important;
  background: #fff !important;
  border: 1px solid #0878d4;
  box-shadow: none !important;
}
.filterationBox {
  border: 1px solid #efefef;
  padding: 10px 20px 10px;
  margin-bottom: 20px;
  header {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
    font-weight: 500;
  }
  .toogleBox {
    display: none;
  }
  .filterBox {
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 15px;
    li {
      font-size: 14px;
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      a {
        text-decoration: none;
        color: #000;
      }
    }
  }
  .detailList {
    padding-left: 0;
    margin-bottom: 10px;
    li {
      color: #8c8c8c;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0;
      .v-icon {
        margin-left: 10px;
        font-size: 20px;
      }
    }
  }
}
.v-input {
  margin-top: 0 !important;
}
a {
  text-decoration: none;
}
p {
  margin-bottom: 3px;
}
.orders {
  display: flex;
  padding-left: 0;
  list-style: none;
  flex-wrap: wrap;
  margin: 0 -15px;
  li {
    width: 50%;
    @media only screen and (max-width:991px){
      width: 100%;
    }
    padding: 5px 10px;
    .offerCard {
      box-shadow: 0 0 5px #e0dddd;
      border-radius: 10px;
      padding: 15px;
      display: flex;
      justify-content: flex-start;
      .image {
        width: 100px;
        height: 100px;
        background: #f4f4f4;
        img {
          object-fit: contain;
          width: 100px;
          height: 100px;
          border-radius: 10px;
        }
        margin-right: 10px;
      }
      .offerDescription {
        width: 75%;
        padding-left: 15px;
        .offerTitle {
          color: rgba(26, 26, 26, 1);
          font-weight: 600;
          font-size: 20px;
        }
        .price {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          p {
            font-size: 16px;
          }
        }
      }
    }
    .text-red {
      color: rgba(197, 46, 127, 1);
      font-weight: 700;
    }
    .text-yellow {
      color: #f9be27 !important;
      font-size: 18px;
    }
    .salePrice {
      position: relative;
      text-decoration: line-through;
      &::after {
        position: absolute;
        text-decoration: none;
      }
    }
  }
}
</style>